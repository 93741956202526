export const VISION_STATEMENT =
  "To raise up kingdom workers who are transformed by Christ to influence the world.";

export const FIVE_CORE_VALUES = [
  "Bible Based",
  "Prayer Driven",
  "Spirit Led",
  "Community Centered",
  "Missions Focused",
];
